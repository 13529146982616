class Navigator {

    static goto(path) {
        console.log('%c Navigator.goto: ', 'background: #000; color: #d946ef;', path);

        window.location.href = path;
    }

}

export default Navigator;
